import React from 'react';
import loadable from '@loadable/component'

import { theme } from '../styles/theme';
import { ThemeProvider } from '../styles/ThemeProvider';

const WholePageOpacity = loadable(() => import('../animations/whole-page-opacity'))
const ContactMainComponent = loadable(() => import('../components/contact/contact-main'))
const Layout = loadable(() => import('../components/Layout'))
const Head = loadable(() => import('../components/Head'))


const contact = () => {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
              <Head title="Contact" />
              <WholePageOpacity><ContactMainComponent /></WholePageOpacity>
       
      </Layout>
    </ThemeProvider>
  );
};

export default contact;
